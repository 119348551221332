<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Plan details</h4>

        <div class="row mt-5">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <strong>Status</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>
                            {{ 
                                user_data.status == 1 ? 
                                "Paid" : user_data.status == 13 ? 
                                "Free Plan" : user_data.status == 7 ? 
                                "To Cancel" : user_data.status == 0 ?
                                "Cancelled" : ""
                            }}
                        </label>
                    </div>
                </div>
                <div v-if="user_data.status != 13 && user_data.status != 0">
                    <div class="row mt-3">
                        <div class="col">
                            <strong>Plan name</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{ user_data.current_plan_name }}</label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <strong>Cost</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{ user_data.cost }}</label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <strong>Cycle</strong>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{ user_data.cycle == 1 ? "Yearly" : "Monthly" }}</label>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="user_data.status == 1">
                        <div class="col">
                            <strong>Next charge</strong>
                        </div>
                    </div>
                    <div class="row" v-if="user_data.status == 1">
                        <div class="col">
                            <label>{{ user_data.next_charge_text }}</label>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="user_data.status == 7">
                        <div class="col">
                            <strong>Cancellation date</strong>
                        </div>
                    </div>
                    <div class="row" v-if="user_data.status == 7">
                        <div class="col">
                            <label>{{ user_data.cancellation_date_format }}</label>
                        </div>
                    </div>
                    <div class="mt-4" v-if="user_data.status == 1">
                        <div class="col">
                            <button @click="showCancelSubscription()" class="btn btn-danger">Cancel subscription</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal"
        id="cancel-subscription-modal"
        tabindex="-1"
        aria-labelledby="CancelSubscriptionModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="CancelSubscriptionModalLabel">
                        Are you sure?
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        No
                    </button>
                    <button
                        @click="cancelSubscription()"
                        type="button"
                        class="btn btn-primary"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import store from "../store";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            user_data: {},
            cancel_subscription_modal: null,
            user_id: 0
        }
    },
    methods: {
        async readUserPlanDetails() {
            let __this = this;

            let url = process.env.VUE_APP_API_URL + "users/plan-details/" + __this.user_id;

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            let data = await res.json();
            if (data && data.user) {
                __this.user_data = data.user;
            }
        },
        cancelSubscription() {
            let __this = this;
            axios
                .post(
                    process.env.VUE_APP_API_URL + "users/cancel-account", {},
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                store.getters.logged_in_user.data.token,
                        },
                    }
                )
                .then(
                    (response) => {
                        console.log(response);
                        __this.readUserPlanDetails();
                        this.cancel_subscription_modal.hide();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        showCancelSubscription() {
            console.log(this.cancel_subscription_modal);
            this.cancel_subscription_modal.show();
        },
    },
    mounted() {
        let __this = this;        
        __this.user_id = this.$store && this.$store.getters && 
            this.$store.getters.logged_in_user &&
                this.$store.getters.logged_in_user.data &&
                this.$store.getters.logged_in_user.data.user.id ? this.$store.getters.logged_in_user.data.user.id : 0;
        __this.readUserPlanDetails();
        __this.cancel_subscription_modal = new Modal(document.getElementById("cancel-subscription-modal"));
    },
};
</script>
