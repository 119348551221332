<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">My Widgets</h4>
        <router-link
            to="/create-widget/"
            class="btn btn-primary btn-md float-end"
        > Create widget
        </router-link>
        <Create :create_modal="create_modal" :widget_types="widget_types" />
        <Read ref="readComponent" />
        <Delete ref="deleteComponent" />
    </div>
</template>

<script>
import Create from "./Create";
import Read from "./Read";
import Delete from "./Delete";
import { Modal } from "bootstrap";
import store from "../../../store";

export default {
    components: {
        Create,
        Delete,
        Read,
    },
    data() {
        return {
            create_widget_modal: null,
            widget_types: [],
        };
    },
    methods: {
        showCreateModal() {
            this.create_modal.show();
            document.getElementById("widget_type_id").selectedIndex = 0;
        },
        async readWidgetTypes(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            if (!url) {
                url = process.env.VUE_APP_API_URL + "widget-types/all";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            this.widget_types = await res.json();
            console.log(document.getElementById("widget_type_id"));

            if (event) {
                event.target.disabled = false;
            }
        },
    },
    mounted() {
        this.readWidgetTypes();
        this.create_modal = new Modal(
            document.getElementById("create_widget_modal")
        );
    },
};
</script>
