<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Profile</h4>

        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" name="name" v-model="name" required>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <label>Primary email</label>
                        <input type="email" class="form-control" name="email" v-model="email" required>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <label>Secondary email</label>
                        <input type="email" class="form-control" name="secondary_email" v-model="secondary_email">
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <label>Company name</label>
                        <input type="text" class="form-control" name="company" v-model="company">
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </form>

    </div>
</template>

<script>

import axios from "axios";
import store from "../store";

export default {
    data() {
        return {
            name: '',
            email: '',
            secondary_email: '',
            company: ''
        }
    },
    methods: {
        submitForm() {
            this.updateProfile();
        },
        async updateProfile() {
            axios.put(
                process.env.VUE_APP_API_URL + "users/update",
                {
                    id: this.$store.getters.logged_in_user.data.user.id,
                    name: this.name,
                    email: this.email,
                    secondary_email: this.secondary_email,
                    company: this.company
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + store.getters.logged_in_user.data.token,
                    },
                }
            )
                .then(
                    (response) => {
                        if (response.data.success) {
                            alert('Profile was updated successfully!')
                        }
                        else {
                            alert('Posting failed')
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        async readUser() {

            let url = process.env.VUE_APP_API_URL + "users/read/" + store.getters.logged_in_user.data.user.id;

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            let user = await res.json();
            this.name = user.name;
            this.email = user.email;
            this.secondary_email = user.secondary_email;
            this.company = user.company;
        }
    },
    mounted() {
        this.readUser();
    }
};
</script>
