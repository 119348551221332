<template>
    <div
        class="modal"
        id="embed_code_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Embed Code
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <textarea readonly type="text" class="form-control mb-3" rows="5" id="embed-code"></textarea>
                    <button @click="copyCode()" class="btn btn-primary">Copy</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            embed_code_modal: null,
        };
    },
    methods: {
        showEmbedCode(widget_id) {
            this.embed_code_modal.show();
            var embed_code = `<div webynize_id="`+widget_id+`"></div>`;
            embed_code += `<script src="${process.env.VUE_APP_WIDGET_URL}tooltips.js"></`+`script>`;
            document.getElementById("embed-code").value = embed_code;
        },
        copyCode() {
            // Get the text field
            var copyText = document.getElementById("embed-code");

            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);

        },
    },
    mounted() {
        this.embed_code_modal = new Modal(document.getElementById("embed_code_modal"));
    },
};
</script>