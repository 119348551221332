<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Explore More Widgets</h4>
        <div class="row mt-5 mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>Live</h2>
                        <div class="loader" v-if="loading"></div>
                        <div class="row g-3">
                            <div class="col-lg-4 col-md-6" v-for="widget_type in live_widget_types" :key="widget_type.id">
                                <div class="card rounded">
                                    <div class="card-body p-3">
                                        <h5 class="card-title">{{ widget_type.name }}</h5>
                                        <p class="card-text overflow-hidden" style="height: 100px;">{{ widget_type.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5">
                        <h2>Coming Soon</h2>
                        <div class="loader" v-if="loading"></div>
                        <div class="row g-3">
                            <div class="col-lg-4 col-md-6" v-for="widget_type in upcoming_widget_types" :key="widget_type.id">
                                <div class="card rounded">
                                    <div class="card-body p-3">
                                        <h5 class="card-title">{{ widget_type.name }}</h5>
                                        <p class="card-text overflow-hidden" style="height: 100px;">{{ widget_type.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import store from "../store";

export default {
    data() {
        return {
            loading: true,
            live_widget_types: [],
            upcoming_widget_types: [],
        }
    },
    methods: {
        async readWidgetTypes(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            if (!url) {
                url = process.env.VUE_APP_API_URL + "widget-types/all";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            this.widget_types = await res.json();
            this.loading = false;
            for (var i = 0; i < this.widget_types.length; i++) {
                if (this.widget_types[i].status == 1) {
                    this.live_widget_types.push(this.widget_types[i]);
                } else {
                    this.upcoming_widget_types.push(this.widget_types[i]);
                }
            }

            if (event) {
                event.target.disabled = false;
            }
        },
    },
    mounted() {
        let __this = this;        

        __this.readWidgetTypes();
    },
};
</script>
