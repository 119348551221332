<template>
    <div v-if="is_logged_in && show_menu" class="col-sm-3">
        <div class="flex-shrink-0 p-3 bg-white" style="width: 280px">
            <ul class="list-unstyled ps-0">
                <li v-for="(navItem, i) in menuList" :key="i" class="mb-1">
                    <button
                        class="
                            btn btn-toggle
                            align-items-center
                            rounded
                            collapsed
                        "
                        data-bs-toggle="collapse"
                        data-bs-target="#home-collapse"
                        aria-expanded="navItem.expand"
                        @click.prevent="navItemCollapse(i)"
                    >
                        {{ navItem.name }}
                    </button>

                    <div
                        v-if="navItem.items.length > 0"
                        class="collapse"
                        :class="{ show: navItem.expand }"
                    >
                        <ul
                            class="
                                btn-toggle-nav
                                list-unstyled
                                fw-normal
                                pb-1
                                small
                            "
                        >
                            <li v-for="(subItem, j) in navItem.items" :key="j">
                                <router-link
                                    :to="{ path: subItem.link }"
                                    :class="
                                        $route.name == subItem.route_name
                                            ? 'active'
                                            : ''
                                    "
                                    class="link-dark rounded"
                                    >{{ subItem.name }}</router-link
                                >
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show_menu: true,
            menuList: [],
        };
    },
    name: "CustomerSidebar",
    computed: {
        is_logged_in: function () {
            return this.$store.getters.is_authenticated;
        },
    },
    watch: {
        $route() {
            var __this = this;
            __this.menuList = [
                {
                    name: "Widgets",
                    expand: false,
                    items: [
                        {
                            name: "My Widgets",
                            link: "/widgets",
                            route_name: "Widgets"
                        },
                        {
                            name: "Explore more widgets",
                            link: "/explore-more-widgets",
                            route_name: "ExploreMoreWidgets",
                        },
                        {
                            name: "What's new?",
                            link: "#",
                        }
                    ],
                },
                {
                    name: "Subscription",
                    expand: false,
                    items: [
                        {
                            name: "Plan details",
                            link: "/account/plan-details",
                            route_name: "PlanDetails"
                        },
                        {
                            name: "Change plan",
                            link: "#",
                        },
                        {
                            name: "Invoices",
                            link: "#",
                        }
                    ],
                },
                {
                    name: "Account",
                    expand: false,
                    items: [
                        {
                            name: "Profile",
                            link: "/account/profile",
                            route_name: "Profile"
                        },
                        {
                            name: "Change password",
                            link: "/account/change-password",
                            route_name: "ChangePassword"
                        },
                        {
                            name: "Views",
                            link: "#",
                        }
                    ],
                }
            ];

            if (
                __this.$store.getters.logged_in_user &&
                __this.$store.getters.logged_in_user.data.user.access_level ==
                    "support"
            ) {
                __this.menuList = __this.menuList.splice(
                    0,
                    __this.menuList.length - 2
                );
            }
            this.showSidebar();
            this.detectLink(this.$route);
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
        detectLink(router) {
            var counter = 0;
            for (let items of this.menuList) {
                for (let item of items.items) {
                    console.log(router.href, item.link);
                    if(item.link == '/widgets'){
                        this.navItemCollapse(counter);
                        break;
                    }
                    else if (item.link == router.href) {
                        this.navItemCollapse(counter);
                        break;
                    }
                }
                counter++;
            }
        },
        navItemCollapse(index) {
            this.menuList = this.menuList.map((item, i) => {
                console.log(item);
                item.expand = !item.expand;
                if (i !== index) {
                    item.expand = false;
                }
                return item;
            });
        },
        showSidebar() {            
            if (this.$route.path.includes("create-widget")
                || this.$route.path.includes("update-widget")
            ) {
                this.show_menu = false;
            } else {
                this.show_menu = true;
            }
        }
    },
    mounted() {
    },
};
</script>