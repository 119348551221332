<template>
    <div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            token: "",
        };
    },
    methods: {
        ...mapActions(["loginAuth"]),
        async login() {
            try {
                var options = window.location.search.slice(1)
                            .split('&')
                            .reduce(function _reduce (a,b) {
                                b = b.split('=');
                                a[b[0]] = decodeURIComponent(b[1]);
                                return a;
                            }, {});
                var user = {
                    token: this.token,
                    email: options.email,
                    password: options.password,
                };
                await this.loginAuth(user);
                this.$router.push("/widgets/");
            } catch {
                console.log("fail");
            }
        },
    },
    mounted(){
        let __this = this;
        const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render='+recaptchaSiteKey;
        script.onload = () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(recaptchaSiteKey, {
                    action: 'submit'
                }).then((token) => {
                    const badgeElement = document.querySelector('.grecaptcha-badge');
                    if (badgeElement) {
                        badgeElement.style.display = 'none';
                    }
                    script.remove();
                    __this.token = token;
                    this.login();
                });
            });
        };
        document.body.appendChild(script);
    }
};
</script>

<style scoped>
</style>