<template>
    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-4">Forgot password?</h5>
                    <div class="mb-3 success" id="message-forgot-password-result">Password reset email sent</div>
                    <form>
                        <label class="form-label">Email</label>
                        <input type="email" class="form-control mb-3" required v-model="email">

                        <button @click="forgotPassword" class="btn btn-primary mb-3">Reset Password</button>

                        <div class="">
                            <router-link to="/login/" class="card-link">Login</router-link>
                            <router-link to="/signup/" class="card-link">Sign up</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-sm-4"></div>
    </div>
</template>

<script>
import axios from "axios";
export default{
    data(){
        return {
            email: ''
        }
    },
    methods: {
        async forgotPassword(event) {
            event.target.disabled = true;

            axios
                .post(
                    process.env.VUE_APP_API_URL + "users/forgot_password",
                    {
                        email: this.email,
                    }
                )
                .then(
                    (response) => {
                        document.getElementById("message-forgot-password-result").classList.remove("error");
                        document.getElementById("message-forgot-password-result").classList.remove("success");
                        document.getElementById("message-forgot-password-result").style.display = "block";
                        document.getElementById("message-forgot-password-result").classList.add(response.data.status);
                        document.getElementById("message-forgot-password-result").innerText = response.data.message;
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
    },
}
</script>

<style scoped>
    .success, .error{
        border-radius: 5px;
        padding: 10px;
    }
    .success{
        background-color: rgb(71 183 71);
        color: white;
    }
    .error{
        background-color: rgb(227 93 105);
        color: white;
    }
    #message-forgot-password-result{
        display: none;
    }
</style>