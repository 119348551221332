<template>
    <!-- edit modal -->
    <div
        class="modal"
        id="edit_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Edit user
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <input name="id" v-model="id" type="hidden" />

                    <div>Name</div>
                    <input
                        name="name"
                        v-model="name"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Description</div>
                    <textarea
                        name="description"
                        v-model="description"
                        type="text"
                        class="form-control mb-3"
                    />
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        @click="update($event)"
                        type="button"
                        class="btn btn-primary"
                    >
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            id: "",
            name: "",
            description: "",
            edit_modal: null,
        };
    },
    methods: {
        resetFields() {
            this.id = "";
            this.name = "";
            this.description = "";
        },
        async update(event) {
            event.target.disabled = true;

            axios
                .put(
                    process.env.VUE_APP_API_URL + "activity-types/update",
                    {
                        id: this.id,
                        name: this.name,
                        description: this.description,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                store.getters.logged_in_user.data.token,
                        },
                    }
                )
                .then(
                    (response) => {
                        if (response.data.success == true) {
                            this.$parent.$refs.readComponent.read();
                        }

                        event.target.disabled = false;
                        this.edit_modal.hide();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        async showEditModal(event, id) {
            event.target.disabled = true;
            this.resetFields();

            // request data from api
            const url =
                process.env.VUE_APP_API_URL + "activity-types/read/" + id;
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });
            var record = await res.json();

            // assign values to model
            this.id = record.id;
            this.name = record.name;
            this.description = record.description;

            this.edit_modal.show();
            event.target.disabled = false;
        },
    },
    mounted() {
        this.edit_modal = new Modal(document.getElementById("edit_modal"));
    },
};
</script>