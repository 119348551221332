<template>
    <div class="container p-5">
        <h4>
            Quick Feedback
            <span class="badge rounded-pill bg-secondary">{{
                data.total
            }}</span>
        <button
            data-bs-toggle="modal"
            @click="$parent.$refs.embedCodeComponent.showEmbedCode($route.params.widget_id)"
            type="button"
            class="btn btn-primary me-2 float-end"
        >
            Embed on website
        </button>
        </h4>

        <br>
        <div class="row">
            <div id="widget-customizer" class="col-md-4 widget-customizer">
                <div class="d-flex align-items-start">
                    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
                        <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
                        <li class="nav-link" id="v-pills-text-tab" data-bs-toggle="pill" data-bs-target="#v-pills-text" type="button" role="tab" aria-controls="v-pills-text" aria-selected="false">Text</li>
                        <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacing</li>
                        
                    </div>
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                            <div class="container p-0 mb-4">
                                <label>Selector</label>
                                <input type="text" class="form-control" v-model="widget_settings.selector" id="selector">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                            <div class="container p-0 mb-4">
                                <label for="">Text color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.text_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                                    <input type="color" class="form-control form-control-color" v-model="widget_settings.text_color">
                                </div>
                            </div>
                            <div class="container p-0 mb-4">
                                <label for="">Background color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.background_color" type="text" id="qf-tb-bg-color" class="form-control setting setting-color">
                                    <input type="color" class="form-control form-control-color" v-model="widget_settings.background_color">
                                </div>
                            </div>
                            <div class="container p-0 mb-4">
                                <label for="">Icon color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.icon_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                    <input type="color" class="form-control form-control-color" v-model="widget_settings.icon_color">
                                </div>
                            </div>
                            <div class="container p-0 mb-4">
                                <label for="">Hover/Chosen icon color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.unselected_icon_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                    <input type="color" class="form-control form-control-color" v-model="widget_settings.unselected_icon_color">
                                </div>
                            </div>
                            <div class="container p-0 mb-4">
                                <label for="">Send button color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.send_button_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                                    <input type="color" class="form-control form-control-color" v-model="widget_settings.send_button_color">
                                </div>
                            </div>
                            <div class="container p-0 mb-4">
                                <label for="">Close button color</label>
                                <div class="form-group">
                                    <input v-model="widget_settings.close_button_color" type="text" id="qf-tb-close-btn-color" class="form-control setting setting-color">
                                    <input type="color" class="form-control form-control-color" v-model="widget_settings.close_button_color">
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-text" role="tabpanel" aria-labelledby="v-pills-text-tab">
                            <div class="container p-0 mb-4">
                                <label for="">Text</label>
                                <input v-model="widget_settings.text" type="text" id="qf-tb-text" class="form-control setting">
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                            <div class="container p-0 mb-4">
                                <label for="">Padding</label>
                                <input v-model="widget_settings.padding" type="number" id="qf-tb-padding" class="form-control setting">
                            </div>
                            <div class="container p-0 mb-4">
                                <label for="">Border radius</label>
                                <input v-model="widget_settings.border_radius" type="number" id="qf-tb-padding" class="form-control setting">
                            </div>

                            
                            <div class="container p-0 mb-4">
                                <label>Show on</label>
                                <select class="form-control" v-model="widget_settings.show_on" id="show-on">
                                    <option value="mouseover">Hover</option>
                                    <option value="click">Click</option>
                                </select>
                            </div>
                            <div class="container p-0 mb-4">
                                <label>Position</label>
                                <select class="form-control" v-model="widget_settings.position" id="position">
                                    <option value="top">Top</option>
                                    <option value="right">Right</option>
                                    <option value="bottom">Bottom</option>
                                    <option value="left">Left</option>
                                </select>
                            </div>
                            <div class="container p-0 mb-4">
                                <label>Notify on (Slack or Google chat webhook URL)</label>
                                <input type="text" class="form-control" v-model="widget_settings.notify_on" id="notify-on">
                            </div>
                        </div>
                        <div class="container p-0 mb-4">
                            <button type="button" class="btn btn-primary" @click="save($event)">Save changes</button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="col-md-8">
                <h5>Live Preview</h5>
                <iframe id="widget_iframe" v-if="widget_settings.widget_id" width="100%" height="500px" :src="widget_url+'quick-feedback/iframe/'+widget_settings.widget_id"></iframe>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>

<script>
import axios from "axios";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            name: "",
            desciption: "",
            edit_modal: null,
            delete_modal: null,
            search_keyword: "",
            notify_on: "",
            widget_settings: {
                text : "How would you rate your experience?",
                text_color: "#000000",
                background_color: "#FFFFFF",
                icon_color: "#000000",
                send_button_color: "#000000",
                close_button_color: "#FF0000",
                unselected_icon_color: "#919191",
                padding: 10,
                border_radius: 8,
            }
        };
    },
    methods: {

        async read() {
            var url =
                process.env.VUE_APP_API_URL +
                `quick-feedbacks/readByWidgetId/${this.widget_id}`;
            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.$store.getters.logged_in_user.data.token,
                 }),
             });
            var data = await res.json();

            if(data){
                this.widget_settings = data;
                this.notify_on = data.notify_on;
            }
        },
        async save(event){
            var __this = this;
            event.target.disabled = false;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;
            axios
            .post(
                process.env.VUE_APP_API_URL + `quick-feedbacks/save/${__this.widget_id}`,
                data,
                 {
                     headers: {
                         Authorization:
                             "Bearer " + this.$store.getters.logged_in_user.data.token,
                     },
                 }
            )
            .then(
                (response) => {
                    if (response.data.id) {
                        this.$parent.$refs.readComponent.read();
                    }
                    event.target.disabled = false;
                    var src = document.getElementById('widget_iframe').src;
                    document.getElementById('widget_iframe').src = src;
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        
        
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
    },
    computed: {
        textStyles() {
            var __this = this;
            return {
                "color": __this.widget_settings.text_color,
            };
        },
        containerStyles() {
            var __this = this;
            return {
                "background-color": __this.widget_settings.background_color,
                "padding": __this.widget_settings.padding + `px`,
                "border-radius": __this.widget_settings.border_radius + `px`
            };
        },
        iconStyles() {
            var __this = this;
            return {
                "color": __this.widget_settings.icon_color,
            };
        },
        closeButtonStyles() {
            var __this = this;
            return {
                "color": __this.widget_settings.close_button_color,
            };
        },
    }
};
</script>
