<template>
    <div
        class="modal"
        id="embed_code_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Embed Code
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Position</label>
                            <select class="form-control" v-model="position" id="position">
                                <option value="1">Lower left</option>
                                <option value="2">Lower right</option>
                                <option value="3">Center</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label>Embed code</label>
                            <textarea class="form-control" id="embed-code"></textarea>
                        </div>
                        <div class="col-md-12 mt-2">
                            <button id="save-change-embed-code" @click="save()" class="btn btn-primary mr-5">Save Changes</button>
                            <button @click="copyCode()" class="btn btn-primary">Copy code</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    select{
        appearance: auto !important;
    }
    .mr-5{
        margin-right: 5px;
    }
</style>
<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_id: "",
            embed_code_modal: null,
            position: 1
        };
    },
    methods: {
        async read() {
            var url =
                process.env.VUE_APP_API_URL +
                `onboarding-checklist/readByWidgetId/${this.widget_id}`;
            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.token,
                 }),
             });
            var data = await res.json();
            if(data){
                this.position = data.position;
            }
        },
        async save(){
            var __this = this;
            var data = {};
            data.widget_id = __this.widget_id;
            data.position = __this.position;
            document.getElementById("save-change-embed-code").innerText = "Saving...";
            axios
            .post(
                process.env.VUE_APP_API_URL + `onboarding-checklist/save`,
                data,
                 {
                     headers: {
                         Authorization:
                             "Bearer " + this.token,
                     },
                 }
            )
            .then(
                (response) => {
                    if (response.data.id) {
                        this.$parent.$refs.readComponent.read();
                    }

                    event.target.disabled = false;
                    document.getElementById("save-change-embed-code").innerText = "Save Changes";
                },
                (error) => {
                    console.log(error);
                    document.getElementById("save-change-embed-code").innerText = "Save Changes";
                }
            );
        },
        showEmbedCode(widget_id) {
            var __this = this;
            __this.widget_id = widget_id;
            __this.read();
            this.embed_code_modal.show();
            var embed_code = `<div webynize_id="`+widget_id+`"></div>`;
            embed_code = embed_code + `<script src="https://widgets.webynize.com/onboardingchecklist.js">` + `</`+`script>`;
            document.getElementById("embed-code").value = embed_code;
        },
        copyCode() {
            var copyText = document.getElementById("embed-code");
            copyText.select();
            copyText.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(copyText.value);
        },
    },
    mounted() {
        this.embed_code_modal = new Modal(document.getElementById("embed_code_modal"));
    },
};
</script>