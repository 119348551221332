<template>
<div id="widget-customizer" class="col-md-4 widget-customizer">
    <div class="d-flex align-items-start">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
            <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
            <li class="nav-link" id="v-pills-font-tab" data-bs-toggle="pill" data-bs-target="#v-pills-font" type="button" role="tab" aria-controls="v-pills-font" aria-selected="false">Fonts</li>
            <li class="nav-link" id="v-pills-alignment-positions-tab" data-bs-toggle="pill" data-bs-target="#v-pills-alignment-positions" type="button" role="tab" aria-controls="v-pills-alignment-positions" aria-selected="false">Alignments and Positions</li>
            <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacing</li>
            
        </div>
        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                <div class="container p-0 mb-4">
                    <label>JSON data URL</label>
                    <input type="text" class="form-control" v-model="widget_settings.data_url" id="data_url">
                </div>
                <div class="container p-0 mb-4">
                    <label>Pages <br>(Separate pages URL by comma)</label>
                    <textarea v-model="widget_settings.pages" class="form-control" name="pages" id="pages" cols="30" rows="10"></textarea>
                </div>
                <div class="container p-0 mb-4">
                    <div class="form-check">
                        <input @change="allPages" value="1" class="form-check-input" type="checkbox" id="all-pages" :checked="widget_settings.pages && widget_settings.pages.length < 1">
                        <label class="form-check-label" for="allow_close">
                            All pages
                        </label>
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label>Hide after</label>
                    <input type="number" class="form-control" v-model="widget_settings.hide_after" id="hide_after">
                </div>
                <div class="container p-0 mb-4">
                    <div class="form-check">
                        <input value="1" v-model="widget_settings.allow_close" class="form-check-input" type="checkbox" id="allow_close" :checked="widget_settings.allow_close == 1">
                        <label class="form-check-label" for="allow_close">
                            Allow close
                        </label>
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <div class="form-check">
                        <input value="1" v-model="widget_settings.loader" class="form-check-input" type="checkbox" id="loader" :checked="widget_settings.loader == 1">
                        <label class="form-check-label" for="loader">
                            Show loader
                        </label>
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label>Width</label>
                    <input type="text" class="form-control" v-model="widget_settings.width" id="width">
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                <div class="container p-0 mb-4">
                    <label for="">Background color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.background_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Content font color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.content_font_color" type="text" id="qf-tb-bg-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.content_font_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Star color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.star_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.star_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Button background color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.button_background_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.button_background_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Button text color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.button_text_color" type="text" id="qf-tb-icon-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.button_text_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Loader color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.loader_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.loader_color">
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-font" role="tabpanel" aria-labelledby="v-pills-font-tab">
                <div class="container p-0 mb-4">
                    <label for="">Content font size</label>
                    <input v-model="widget_settings.content_font_size" type="number" id="qf-tb-text" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Star size</label>
                    <input v-model="widget_settings.star_size" type="number" id="qf-tb-text" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Button font size</label>
                    <input v-model="widget_settings.button_font_size" type="number" id="qf-tb-text" class="form-control setting">
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-alignment-positions" role="tabpanel" aria-labelledby="v-pills-alignment-positions-tab">
                <div class="container p-0 mb-4">
                    <label for="" >Content text align</label>
                    <select v-model="widget_settings.content_alignment" class="form-control setting" name="content_alignment" id="content_alignment">
                        <option value="0">Left</option>
                        <option value="1">Center</option>
                        <option value="2">Right</option>
                    </select>
                </div>
                <div class="container p-0 mb-4">
                    <label for="" >Position</label>
                    <select v-model="widget_settings.position" class="form-control setting" name="position" id="position">
                        <option value="0">Lower Left Corner</option>
                        <option value="1">Lower Center</option>
                        <option value="2">Lower Right Corner</option>
                        <option value="3">Upper Left Corner</option>
                        <option value="4">Upper Center</option>
                        <option value="5">Upper Right Corner</option>
                        <option value="6">Left Center</option>
                        <option value="7">Right Center</option>
                        <option value="8">Center</option>
                    </select>
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                <div class="container p-0 mb-4">
                    <label for="">Padding</label>
                    <input v-model="widget_settings.padding" type="number" id="qf-tb-padding" class="form-control setting">
                </div>
            </div>
            <div class="container p-0 mb-4">
                <button type="button" class="btn btn-primary" @click="save($event)">{{ saveBtnText }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>

<script>
import axios from "axios";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            saveBtnText: "Save changes",
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: "",
            data: "",
            id: "",
            notify_on: "",
            widget_settings: {
                widget_id: 0,
                background_color : "#ffffff",
                content_font_color: "#555555",
                content_font_size: 12,
                content_alignment: 0,
                star_color: "#1e65b5",
                star_size: 12,
                button_background_color: "#1972f5",
                button_text_color: "#ffffff",
                button_font_size: 0,
                padding: 8,
                position: 0,
                hide_after: 6000,
                show_transition: 0,
                allow_close: 0,
                loader: 0,
                loader_color: "#025DFB",
                width: 350,
                data_url: "",
                pages: ""
            }
        };
    },
    methods: {
        async read() {
            var url =
                process.env.VUE_APP_API_URL +
                `social-proofs/readByWidgetId/${this.widget_id}`;
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + this.token,
                }),
            });
            var data = await res.json();

            if(data){
                this.widget_settings = data;
            }
        },
        async save(event){
            var __this = this;
            event.target.disabled = false;
            var data = __this.widget_settings;
            data.widget_id = __this.widget_id;
            __this.saveBtnText = "Saving...";
            axios
            .post(
                process.env.VUE_APP_API_URL + `social-proofs/save/${__this.widget_id}`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + this.token,
                    },
                }
            )
            .then(
                (response) => {
                    if (response.data.id) {
                        event.target.disabled = false;
                        __this.preview();
                        __this.saveBtnText = "Save Changes";
                    }
                },
                (error) => {
                    console.log(error);
                    __this.saveBtnText = "Save Changes";
                }
            );
        },
        allPages() {

            if (event.target.checked) {
                this.widget_settings.pages = "";
            }
        },
        preview (){
            let previewContainer = document.querySelector('[data-webynize-id="' + this.$route.params.widget_id + '"]');
            let socialProofScript = document.querySelector('[src="' + process.env.VUE_APP_WIDGET_URL + 'social-proof.js' + '"]');

            if (previewContainer) {
                previewContainer.remove();
            }

            if (socialProofScript) {
                socialProofScript.remove();
            }
            previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz_social_proof");
            socialProofScript = document.createElement("script");
            socialProofScript.src = process.env.VUE_APP_WIDGET_URL + `social-proof.js`;
            socialProofScript.defer = true;
            document.getElementById("preview").appendChild(previewContainer);
            document.getElementById("preview").appendChild(socialProofScript);

            socialProofScript.onload = function() {
                window.dispatchEvent(new Event('load'));
            };
        }
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
        __this.read();
        __this.preview();
    }
};
</script>
