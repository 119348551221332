<template>
    <!-- create modal -->
    <div
        class="modal"
        id="create_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Create Type
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <input name="id" v-model="id" type="hidden" />

                    <div>User</div>
                    <select
                        id="name"
                        name="name"
                        v-model="user_id"
                        type="text"
                        class="form-control mb-3"
                    >
                        <option
                            v-for="user in users"
                            :key="user.id"
                            :value="user.id"
                        >
                            {{ user.name }}
                        </option>
                    </select>

                    <div>Item</div>
                    <input
                        id="item"
                        name="item"
                        v-model="item"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Cost</div>
                    <input
                        id="cost"
                        name="cost"
                        v-model="cost"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Transaction ID</div>
                    <input
                        id="transaction_id"
                        name="transaction_id"
                        v-model="transaction_id"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Invoice URL</div>
                    <input
                        id="invoice_url"
                        name="invoice_url"
                        v-model="invoice_url"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Billing name</div>
                    <input
                        id="billing_name"
                        name="billing_name"
                        v-model="billing_name"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Billing address</div>
                    <input
                        id="billing_address"
                        name="billing_address"
                        v-model="billing_address"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Payment Date</div>
                    <input
                        id="payment_date"
                        name="payment_date"
                        v-model="payment_date"
                        type="date"
                        class="form-control mb-3"
                    />
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        @click="create($event)"
                        type="button"
                        class="btn btn-primary"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            id: "",
            user_id: "",
            item: "",
            cost: "",
            transaction_id: "",
            invoice_url: "",
            billing_name: "",
            billing_address: "",
            payment_date: "",
            create_modal: null,
            users: [],
        };
    },
    methods: {
        init() {
            var _vm = this;
            _vm.getUsers();
        },
        async getUsers() {
            var _vm = this;
            const res = await fetch(
                process.env.VUE_APP_API_URL + "users/list",
                {
                    headers: new Headers({
                        Authorization:
                            "Bearer " + store.getters.logged_in_user.data.token,
                    }),
                }
            );

            _vm.users = await res.json();
            _vm.users.forEach((element) => {
                console.log(element["email"]);
            });
        },
        showCreateModal() {
            this.create_modal.show();
            document.getElementById("name").focus();
            this.resetFields();
        },
        resetFields() {
            this.id = "";
            this.user_id = "";
            this.item = "";
            this.cost = "";
            this.transaction_id = "";
            this.invoice_url = "";
            this.billing_name = "";
            this.billing_address = "";
            this.payment_date = "";
        },
        async create(event) {
            event.target.disabled = true;

            axios
                .post(
                    process.env.VUE_APP_API_URL + "invoices/create",
                    {
                        user_id: this.user_id,
                        item: this.item,
                        cost: this.cost,
                        transaction_id: this.transaction_id,
                        invoice_url: this.invoice_url,
                        billing_name: this.billing_name,
                        billing_address: this.billing_address,
                        payment_date: this.payment_date,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                store.getters.logged_in_user.data.token,
                        },
                    }
                )
                .then(
                    (response) => {
                        if (response.data.id) {
                            this.$parent.$refs.readComponent.read();
                        }

                        event.target.disabled = false;
                        this.create_modal.hide();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
    },
    mounted() {
        var _vm = this;
        _vm.init();
        _vm.create_modal = new Modal(document.getElementById("create_modal"));
    },
};
</script>