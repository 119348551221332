<template>
    <div class="col-sm-9">
        <h4>
            Onboarding checklist preview
        </h4>
        <div :webynize_id="widget_id"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            widget_id: 0
        };
    },
    mounted() {
        var __this = this;
        __this.widget_id = __this.$route.params.widget_id;
        var script = document.createElement('script');
        script.src = 'https://widgets.webynize.com/onboardingchecklist.js';
        document.body.appendChild(script);
    },
};
</script>
