<template>
    <div class="col-sm-12">
        <h4>
            Product Update items
            <span class="badge rounded-pill bg-secondary">{{
                data.total
            }}</span>
        </h4>
        <div id="preview-btn-container">
        <button
            id="preview-product-update"
            class="btn btn-primary me-2 float-end"
        >
            Preview
        </button>
        </div>
        <button
            @click="$parent.$refs.embedCodeComponent.showEmbedCode($route.params.widget_id)"
            type="button"
            class="btn btn-primary me-2 float-end"
        >
            Embed on website
        </button>
        <button
            @click="$parent.$refs.createComponent.showCreateModal()"
            type="button"
            class="btn btn-primary me-2 float-end"
        >
            Create Product Update item
        </button>

        <form @submit.prevent="search(event)">
            <div class="input-group mb-3 float-end me-2" style="width: 300px">
                <input
                    v-model="search_keyword"
                    type="text"
                    class="form-control"
                    placeholder="Type any word..."
                    aria-label="Search"
                    aria-describedby="button-addon2"
                    v-on:keyup="detectEmptySearchBox(event)"
                />
                <button
                    class="btn btn-secondary"
                    type="submit"
                    id="button-addon2"
                >
                    Search
                </button>
            </div>
        </form>
        <div class="mt-5">
            <div class="row w-100">
                <Sidebar />
                <div class="col-md-8">
                    <table class="table table-hovered">
                        <thead v-if="!data.data || data.data.length < 1">
                            <tr>
                                No data found.
                            </tr>
                        </thead>
                        <thead v-if="data.data && data.data.length > 0">
                            <tr>
                                <th>Title</th>
                                <th>Publish Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="data.data && data.data.length > 0">
                            <tr v-for="item in data.data" :key="item.id">
                                <td class="w-10">{{ item.title }}</td>
                                <td class="w-10">{{ publish_date_formatted(item.publish_date) }}</td>
                                <td class="w-30">
                                    <button
                                        @click="
                                            $parent.$refs.updateComponent.showEditModal(
                                                $event,
                                                item
                                            )
                                        "
                                        type="button"
                                        class="btn btn-primary me-2"
                                    >
                                        Edit
                                    </button>

                                    <button
                                        @click="
                                            $parent.$refs.deleteComponent.showDeleteModal(
                                                item.id,
                                                $route.params.widget_id
                                            )
                                        "
                                        type="button"
                                        class="btn btn-danger me-2"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table><div class="loader" v-if="loading"></div>                    
                    <nav v-if="data.data && data.data.length > 0" aria-label="Page navigation example">
                        <ul class="pagination">
                            <span v-for="item in data.links" :key="item.url" class="me-1">
                                <li :class="{ active: item.active }" class="page-item">
                                    <a
                                        v-if="item.url"
                                        @click="read(event, item.url)"
                                        class="page-link"
                                        href="#"
                                        ><span v-html="item.label"></span
                                    ></a>
                                </li>
                            </span>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .w-30 {
        width: 30% !important;
    }
</style>

<script>

import Sidebar from "./components/Sidebar";

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            loading: true,
            data: "",
            id: "",
            name: "",
            desciption: "",
            edit_modal: null,
            delete_modal: null,
            search_keyword: ""
        };
    },
    methods: {
        async search(event) {
            const search_url =
                process.env.VUE_APP_API_URL +
                `product-update-items/${this.$route.params.widget_id}/` +
                this.search_keyword;

            this.read(event, search_url);
        },

        async detectEmptySearchBox() {
            if (!this.search_keyword) {
                this.read();
            }
        },

        async read(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            // api request
            if (!url) {
                url = process.env.VUE_APP_API_URL + `product-update-items/${this.$route.params.widget_id}`;
            }

            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.token,
                 }),
             });

            if (this.data && this.data.links) {
                this.data.links = "";
            }
            this.data = await res.json();
            this.loading = false;
            if (event) {
                event.target.disabled = false;
            }
        },
        publish_date_formatted(date) {
            const dateObject = new Date(date);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
            return dateObject.toLocaleString('en-US', options);
        },
        initializePreview () {
            let previewContainer = document.createElement("div");
            previewContainer.setAttribute("data-webynize-id", this.$route.params.widget_id);
            previewContainer.classList.add("wbnz_product_update");
            let productUpdateScript = document.createElement("script");
            productUpdateScript.src = process.env.VUE_APP_WIDGET_URL + `product-updates.js`;

            document.body.appendChild(previewContainer);
            document.body.appendChild(productUpdateScript);
        }
    },
    mounted() {
        this.initializePreview();
    }
};
</script>
