<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-5">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img width="200" src="https://webynize.com/app/logo.svg" alt="logo" class="img-fluid">
            </a>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <span v-if="is_logged_in" class="w-100">
                    <ul class="navbar-nav float-start">
                        <li class="nav-item">
                            <router-link
                                    to="/widgets/"
                                    class="nav-link active"
                                    >My widgets</router-link
                                >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                aria-current="page"
                                href="#"
                                >Helpdesk</a
                            >
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">Support</a>
                        </li>
                    </ul>

                    <ul class="navbar-nav float-end">
                        <li class="nav-item">
                            <a @click="logout" class="nav-link">
                                Hi {{ user.name }}! Logout</a
                            >
                        </li>
                    </ul>
                </span>

                <span v-else class="w-100">
                    <ul class="navbar-nav float-end">
                        <li class="nav-item">
                            <router-link to="/login/" class="nav-link"
                                >Login</router-link
                            >
                        </li>
                    </ul>
                </span>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
    computed: {
        is_logged_in: function () {
            return this.$store.getters.is_authenticated;
        },
        user: function () {
            return this.$store.getters.logged_in_user.data.user;
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
    },
};
</script>

<style>
.navbar a:hover {
    cursor: pointer;
}
</style>