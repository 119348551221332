<template>
    <div>
        <Navbar />
        <div class="container-fluid">
            <div
                v-if="
                    $store.getters.logged_in_user &&
                    $store.getters.logged_in_user.data &&
                    $store.getters.logged_in_user.data.user &&
                    $store.getters.logged_in_user.data.user.access_level.toLowerCase() ==
                        'customer'
                "
                class="row"
            >
                <CustomerSidebar />
                <router-view :user="user" />
            </div>
            <div v-else class="row">
                <Sidebar />
                <router-view :user="user" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import CustomerSidebar from "./components/CustomerSidebar";

export default {
    name: "App",
    components: {
        Navbar,
        Sidebar,
        CustomerSidebar,
    },
    data() {
        return {
            user: null,
        };
    },
    async created() {
        const current_user_url = process.env.VUE_APP_API_URL + "users/current";

        const response = await axios.get(current_user_url, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        this.user = response.data;
    },
};
</script>

<style>
@import "./assets/sidebars.css";
</style>
