import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Login from '../views/Login'
import GoogleAuth from '../views/GoogleAuth'
import Signup from '../views/Signup'
import Forgot from '../views/Forgot'
import Reset from '../views/Reset'
import Users from '../views/admin/users/Users'
import ActivityTypes from '../views/admin/activity_types/ActivityTypes'
import WidgetCategories from '../views/admin/widget_categories/WidgetCategories'
import Plans from '../views/admin/plans/Plans'
import WidgetTypes from '../views/admin/widget_types/WidgetTypes'
import CannedResponses from '../views/admin/canned_responses/CannedResponses'
import Invoices from '../views/admin/invoices/Invoices'
import Stats from '../views/admin/stats/Stats'
import Settings from '../views/admin/settings/Settings'
import Widgets from '../views/users/widgets/Widgets'
import CreateWidget from '../views/users/widgets/CreateWidget'
import Tooltips from '../views/users/tooltips/Tooltips'
import OnboardingChecklistItems from '../views/users/onboarding_checklist_items/OnboardingChecklistItems'
import OnboardingChecklistPreview from '../views/users/onboarding_checklist_items/Preview'
import QuickFeedback from '../views/users/quick-feedback/QuickFeedback'
import SocialProof from '../views/users/social-proofs/SocialProof'
import VoteButton from '../views/users/vote-button/VoteButton'
import ProductUpdateItems from '../views/users/product-update/ProductUpdateItems'
import Profile from '../views/Profile'
import ChangePassword from '../views/ChangePassword'
import PlanDetails from '../views/PlanDetails'
import ExploreMoreWidgets from '../views/ExploreMoreWidgets'

const routes = [
    {
        path: '/', name: 'Home',
        meta: { login_needed: true }
    },
    {
        path: '/google-auth/', name: 'GoogleAuth', component: GoogleAuth,
        meta: { login_needed: false }
    },
    {
        path: '/login/', name: 'Login', component: Login,
        meta: { login_needed: false }
    },
    {
        path: '/signup/', name: 'Signup', component: Signup,
        meta: { login_needed: false }
    },
    {
        path: '/forgot/', name: 'Forgot', component: Forgot,
        meta: { login_needed: false }
    },
    {
        path: '/reset-password/:key/', name: 'Reset', component: Reset,
        meta: { login_needed: false }
    },
    {
        path: '/admin/canned-responses/', name: 'CannedResponses', component: CannedResponses,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/admin-stats/', name: 'Stats', component: Stats,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/admin-settings/', name: 'Settings', component: Settings,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/widget-categories/', name: 'WidgetCategories', component: WidgetCategories,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/activity-types/', name: 'ActivityTypes', component: ActivityTypes,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/plans/:status/', name: 'Plans', component: Plans,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/users/', name: 'Users', component: Users,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/widget-types/', name: 'WidgetTypes', component: WidgetTypes,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/invoices/', name: 'Invoices', component: Invoices,
        meta: { login_needed: true, admin: true, support: true }
    },
    {
        path: '/widgets/', name: 'Widgets', component: Widgets,
        meta: { login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/create-widget/', name: 'CreateWidget', component: CreateWidget,
        meta: { login_needed: false, admin: false, support: false, customer: false }
    },
    {
        path: '/update-widget/tooltips/:widget_id/', name: 'Tooltips', component: Tooltips,
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/onboarding-checklist-items/:widget_id/', name: 'OnboardingChecklistItems', component: OnboardingChecklistItems, 
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/onboarding-checklist-items/preview/:widget_id/', name: 'OnboardingChecklistPreview', component: OnboardingChecklistPreview, 
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/quick-feedback/:widget_id/', name: 'QuickFeedback', component: QuickFeedback, 
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/product-updates/:widget_id/', name: 'ProductUpdateItems', component: ProductUpdateItems, 
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/social-proofs/:widget_id/', name: 'Social proof', component: SocialProof, 
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/vote-button/:widget_id/', name: 'VoteButton', component: VoteButton, 
        meta: { login_needed: false, admin: false, support: true, customer: true }
    },
    {
        path: '/account/profile/', name: 'Profile', component: Profile,
        meta: { login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/account/change-password/', name: 'ChangePassword', component: ChangePassword,
        meta: { login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/account/plan-details/', name: 'PlanDetails', component: PlanDetails,
        meta: { login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/explore-more-widgets/', name: 'ExploreMoreWidgets', component: ExploreMoreWidgets,
        meta: { login_needed: true, admin: true, support: true, customer: true }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    var current_path = to.path;
    var access_level = "";
    var is_authenticated = false;
   
    if (store
        && store.getters
        && store.getters.logged_in_user
        && store.getters.logged_in_user.data
        && store.getters.logged_in_user.data.token) {
        localStorage.setItem("access_level", store.getters.logged_in_user.data.user.access_level);
        access_level = store.getters.logged_in_user.data.user.access_level;
        is_authenticated = store.getters.is_authenticated;
    }

    // if not logged in and login_needed is true, redirect to login

    if (!is_authenticated && to.meta.login_needed) {
        console.log('redirect to login')
        next('/login');
    }

    // if not authenticated and there's no need to login
    else if (!to.meta.login_needed) {
        next();
    }

    // if user goes to login page and was already logged in
    else if (current_path == '/login' && is_authenticated) {
        
        var access_level_login  = access_level.toLowerCase();
        if (access_level_login == 'customer') {
            next('/widgets');
        }

        if (access_level_login == 'admin' || access_level_login == 'support') {
            // if admin or support, go to users list
            next('/admin/users');
        }
    }
    // if user goes to signup page and was already logged in
    else if (current_path == '/signup' && is_authenticated) {
        // if customer, go to my widgets
        var access_level_signup  = access_level.toLowerCase();
        if (access_level_signup == 'customer') {
            next('/widgets');
        }

        if (access_level_signup == 'admin' || access_level_signup == 'support') {
            // if admin or support, go to users list
            next('/admin/users');
        }
    }

    // if authenticated admin
    else if (is_authenticated && to.meta.admin && access_level.toLowerCase() == 'admin') {
        next();
    }

    // if authenticated support
    else if (is_authenticated && to.meta.support && access_level.toLowerCase() == 'support') {
        next();
    }

    // if authenticated customer
    else if (is_authenticated && to.meta.customer && access_level.toLowerCase() == 'customer') {
        next();
    }
});

/*
// who's the user?
if (store
    && store.getters
    && store.getters.logged_in_user
    && store.getters.logged_in_user.data
    && store.getters.logged_in_user.data.token) {
    console.log('user access_level', store.getters.logged_in_user.data.user.access_level)
    console.log('user token', store.getters.logged_in_user.data.token)
}

    console.log('access_level: ', access_level)
    console.log('is_authenticated: ', is_authenticated)
    console.log('token: ', token)

    console.log('to: ', to)
    console.log('current_path: ', current_path)

    console.log('to.meta.login_needed: ', to.meta.login_needed)
    console.log('to.meta.admin: ', to.meta.admin)
    console.log('to.meta.support: ', to.meta.support)
    console.log('to.meta.customer: ', to.meta.customer)

// guard for login_needed
router.beforeEach((to, from, next) => {

    console.log('to.meta.login_needed', to.meta.login_needed)

    if (to.matched.some((record) => record.meta.login_needed)) {
        if (store.getters.is_authenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

// guard for guest
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {

        if (store.getters.is_authenticated) {
            next("/admin/users");
            return;
        }

        next();
    } else {
        next();
    }
});

// guard for admin pages only
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiredAdmin)) {

        if (store.getters.is_authenticated && store.getters.logged_in_user.data.user.access_level != "Admin") {
            next("/admin/users");
            return;
        }

        next();
    } else {
        next();
    }
});

// guard for atleast support pages only
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiredAtleastSupport)) {
        if (store.getters.is_authenticated &&
            (store.getters.logged_in_user.data.user.access_level != "admin" &&
                store.getters.logged_in_user.data.user.access_level != "support")) {
            next("/users/widgets");
            return;
        }

        next();
    } else {
        next();
    }
});
*/

export default router
