import axios from 'axios';
import router from '@/router';

const state = {
    user: null
};

const getters = {
    is_authenticated: state => !!state.user,
    logged_in_user: state => state.user,
};

const actions = {

    // called when user signs up
    async signUpAuth({ dispatch }, user) {

        var temp_pw = (Math.random() + 1).toString(36).substring(7);
        var user_data = {
            token: user.token,
            name: "No name",
            email: user.email,
            password: temp_pw,
            access_level: "customer"
        }

        // creat user
        await axios.post(process.env.VUE_APP_API_URL + "signup", user_data)
            .then(async function (response) {
                if (response.data.success) {
                    const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
                    window.grecaptcha.ready(() => {
                        window.grecaptcha.execute(recaptchaSiteKey, {
                            action: 'submit'
                        }).then((token) => {
                            user_data.token = token;
                            console.log(user_data);
                            dispatch('loginAuth', user_data);
                        });
                    });
                }
                else {
                    alert('Email is already exists.')
                }
            },
            (error) => {
                console.log(error);
            });
    },

    // called when user needs to login
    async loginAuth({ commit }, user) {
        const user_data = await axios
            .post(process.env.VUE_APP_API_URL + "users/login", {
                token: user.token,
                email: user.email,
                password: user.password,
            });
        if (user_data && user_data.data && user_data.data.token) {
            console.log(router);
            // token will be used for every api request
            localStorage.setItem("token", user_data.data.token);

            await commit('setUser', user_data);

            if(user_data.data.access_level == "admin"){
                router.push("/admin/users/");
            }
            else{
                router.push("/widgets/");
            }
        }
    },

    // called when user click logout
    async logoutAuth({ commit }) {

        // must destroy the token on the server

        // change user state
        commit('logout')
    }
};

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    logout(state) {
        state.user = null
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};