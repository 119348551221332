<template>
    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-4">Reset password</h5>
                    <div class="mb-3 success" id="message-forgot-password-result">Password reset email sent</div>
                    <form>
                        <label class="form-label">New password</label>
                        <input type="password" class="form-control mb-3" required v-model="password">
                        <label class="form-label">Confirm new password</label>
                        <input type="password" class="form-control mb-3" required v-model="confirm_password">
                        <input type="hidden" name="account_key" v-model="account_key">
                        <button @click="resetPassword" class="btn btn-primary mb-3">Update password</button>

                        <div class="">
                            <router-link to="/login/" class="card-link">Login</router-link>
                            <router-link to="/signup/" class="card-link">Sign up</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-sm-4"></div>
    </div>
</template>

<script>
import axios from "axios";
export default{
    data(){
        return {
            password: '',
            confirm_password: '',
            account_key: this.$route.params.key
        }
    },
    methods: {
        async resetPassword(event) {
            var __this = this;
            event.target.disabled = true;
            if(this.password == this.confirm_password){
                axios
                    .put(
                        process.env.VUE_APP_API_URL + "users/update-password",
                        {
                            password: this.password,
                            account_key: this.account_key
                        }
                    )
                    .then(
                        (response) => {
                            document.getElementById("message-forgot-password-result").classList.remove("error");
                            document.getElementById("message-forgot-password-result").classList.remove("success");
                            document.getElementById("message-forgot-password-result").style.display = "block";
                            document.getElementById("message-forgot-password-result").classList.add(response.data.status);
                            document.getElementById("message-forgot-password-result").innerText = response.data.message;
                            setTimeout(function(){
                                __this.$route.push({
                                    path: '/login'
                                });
                            },3000);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            } else {
                alert("Password must match.");
            }
        },
    },
}
</script>

<style scoped>
    .success, .error{
        border-radius: 5px;
        padding: 10px;
    }
    .success{
        background-color: rgb(71 183 71);
        color: white;
    }
    .error{
        background-color: rgb(227 93 105);
        color: white;
    }
    #message-forgot-password-result{
        display: none;
    }
</style>