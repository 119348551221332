<template>
<div id="widget-customizer" class="col-md-4 widget-customizer">
    <div class="d-flex align-items-start">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <li class="nav-link active" id="v-pills-basic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-basic" type="button" role="tab" aria-controls="v-pills-basic" aria-selected="false">Basic</li>
            <li class="nav-link" id="v-pills-colors-tab" data-bs-toggle="pill" data-bs-target="#v-pills-colors" type="button" role="tab" aria-controls="v-pills-colors" aria-selected="false">Colors</li>
            <li class="nav-link" id="v-pills-font-tab" data-bs-toggle="pill" data-bs-target="#v-pills-font" type="button" role="tab" aria-controls="v-pills-font" aria-selected="false">Fonts</li>
            <li class="nav-link" id="v-pills-spacing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-spacing" type="button" role="tab" aria-controls="v-pills-spacing" aria-selected="false">Spacing</li>
        </div>
        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-basic" role="tabpanel" aria-labelledby="v-pills-basic-tab">
                <div class="container p-0 mb-4">
                    <label>Selector</label>
                    <input type="text" class="form-control" v-model="widget_settings.selector" id="selector">
                </div>
                <div class="container p-0 mb-4">
                    <label>Popup Title</label>
                    <input type="text" class="form-control" v-model="widget_settings.popup_title" id="popup_title">
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-colors" role="tabpanel" aria-labelledby="v-pills-colors-tab">
                <div class="container p-0 mb-4">
                    <label for="">Popup title text color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.popup_title_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.popup_title_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Title text color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.title_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.title_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Product update text color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.product_update_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.product_update_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Product update background color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.product_update_background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.product_update_background_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Link color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.link_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.link_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Background color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.background_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Badge text color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.badge_text_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.badge_text_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Badge background color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.badge_background_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.badge_background_color">
                    </div>
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Close button color</label>
                    <div class="form-group">
                        <input v-model="widget_settings.close_button_color" type="text" id="qf-tb-text-color" class="form-control setting setting-color">
                        <input type="color" class="form-control form-control-color" v-model="widget_settings.close_button_color">
                    </div>
                </div>
            </div>            
            <div class="tab-pane fade" id="v-pills-font" role="tabpanel" aria-labelledby="v-pills-font-tab">
                <div class="container p-0 mb-4">
                    <label for="">Popup title font size</label>
                    <input v-model="widget_settings.popup_title_font_size" type="number" id="qf-tb-text" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Title font size</label>
                    <input v-model="widget_settings.title_font_size" type="number" id="qf-tb-text" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Product update font size</label>
                    <input v-model="widget_settings.product_update_font_size" type="number" id="qf-tb-text" class="form-control setting">
                </div>
            </div>
            <div class="tab-pane fade" id="v-pills-spacing" role="tabpanel" aria-labelledby="v-pills-spacing-tab">
                <div class="container p-0 mb-4">
                    <label for="">Padding</label>
                    <input v-model="widget_settings.padding" type="number" id="qf-tb-padding" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Border radius</label>
                    <input v-model="widget_settings.border_radius" type="number" id="qf-tb-padding" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Product update padding</label>
                    <input v-model="widget_settings.product_update_padding" type="number" id="qf-tb-padding" class="form-control setting">
                </div>
                <div class="container p-0 mb-4">
                    <label for="">Product update border radius</label>
                    <input v-model="widget_settings.product_update_border_radius" type="number" id="qf-tb-padding" class="form-control setting">
                </div>
            </div>
            <div class="container p-0 mb-4">
                <button type="button" class="btn btn-primary" @click="save($event)">{{ saveBtnText }}</button>
            </div>
        </div>
   </div>
</div>
</template>
<style scoped>
    .widget-customizer{
        border: 1px solid #c6c6c6;
        border-radius: 8px;
        background: #000000;
        color:white;
        padding:20px 10px;
    }
    .widget-customizer .form-group{
        display:inline-flex;
    }

    .widget-customizer .nav-link{
        color: white;
        background: black;
        border-right: 1px solid #555 !important;
        border-radius:0px;
    }
    .widget-customizer .nav-link.active{
        color: white;
        background: black;
        border: 1px solid #555 !important;
        border-right: none !important;
        border-radius: 4px 0 0 4px;
    }
    .widget-customizer .container{
        display:grid;
    }
</style>
<script>

import axios from "axios";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_id: 0,
            saveBtnText: "Save changes",
            widget_settings: {
                popup_title: "Product Updates",
                popup_title_color: "#555555",
                popup_title_font_size : 21,
                title_color : "#555555",
                title_font_size : 18,
                product_update_color: "#555555",
                product_update_font_size : 15,
                product_update_padding : 20,
                product_update_border_radius : 8,
                product_update_background_color : "#ffffff",
                link_color: "#3480dc",
                background_color: "#ffffff",
                close_button_color: "#555555",
                padding: 20,
                border_radius: 8,
                badge_text_color: "#555555",
                badge_background_color: "#ffffff",
                selector: ""
            }
        };
    },
    methods: {
        async read() {
            var url =
                process.env.VUE_APP_API_URL +
                `product-update/readByWidgetId/${this.widget_id}`;
            console.log(url);
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + this.token,
                }),
            });
            var data = await res.json();
            if(data){
                this.widget_settings = data.settings;
            }
        },
        async save(event){
            var __this = this;
            var data = {};
            data = __this.widget_settings;
            data.widget_id = __this.widget_id;
            __this.saveBtnText = "Saving...";
            axios
            .post(
                process.env.VUE_APP_API_URL + `product-update/save`,
                data,
                 {
                     headers: {
                         Authorization:
                             "Bearer " + this.token,
                     },
                 }
            )
            .then(
                (response) => {
                    if (response.data.success) {
                        let preview_btn = document.getElementById("preview-product-update");
                        preview_btn.setAttribute("reload", "true");
                        setTimeout(function(){
                            preview_btn.removeAttribute("reload");
                        },3000);
                        event.target.disabled = false;
                        __this.saveBtnText = "Save Changes";
                    }
                },
                (error) => {
                    console.log(error);
                    __this.saveBtnText = "Save Changes";
                }
            );
        },
    },
    mounted() {
        let __this = this;
        __this.widget_id = __this.$route.params.widget_id;
        __this.read();
    },
};
</script>
