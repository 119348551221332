<template>
    <Create ref="createComponent" />
    <Read ref="readComponent" />
    <Update ref="updateComponent" />
    <Delete ref="deleteComponent" />
</template>

<script>
import Read from "./Read";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";

export default {
    components: {
        Read,
        Update,
        Delete,
        Create
    },

    mounted() {
        this.$refs.readComponent.read();
    },
};
</script>

<style scoped>
    body {
        background:red;
    }
</style>