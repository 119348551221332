<template>
    <div
        class="modal"
        id="embed_code_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Embed Code
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Embed code</label>
                            <textarea rows="3" class="form-control" id="embed-code"></textarea>
                        </div>
                        <div class="col-md-12 mt-2">
                            <button @click="copyCode()" class="btn btn-primary">Copy code</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    select{
        appearance: auto !important;
    }
    .mr-5{
        margin-right: 5px;
    }
</style>
<script>
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            widget_id: "",
            embed_code_modal: null
        };
    },
    methods: {
        showEmbedCode(widget_id) {
            var __this = this;
            __this.widget_id = widget_id;
            this.embed_code_modal.show();
            var embed_code = `<div class="wbnz_product_update" data-webynize-id="`+widget_id+`"></div>`;
            embed_code = embed_code + `<script src="`+process.env.VUE_APP_WIDGET_URL+`product-updates.js">` + `</`+`script>`;
            document.getElementById("embed-code").value = embed_code;
        },
        copyCode() {
            var copyText = document.getElementById("embed-code");
            copyText.select();
            copyText.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(copyText.value);
        },
    },
    mounted() {
        this.embed_code_modal = new Modal(document.getElementById("embed_code_modal"));
    },
};
</script>