<template>
    <div
        class="modal"
        id="embed_code_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Embed Vote Button Widget
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        
                        <div class="row">
                            <div class="col-md-12">
                                <label>Embed code</label>
                                <textarea class="form-control" id="embed-code"></textarea>
                            </div>
                            <div class="col-md-12 mt-2">
                                <button @click="copyCode()" class="btn btn-primary">Copy code</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    select{
        appearance: auto !important;
    }
    .mr-5{
        margin-right: 5px;
    }
</style>

<script>
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            widget_id: "",
            embed_code_modal: null,
            widget_settings: {
                selector: "",
                show_on: "mouseover",
                position: "bottom",
                notify_on: ""
            }
        };
    },
    methods: {
        async read() {
            var url =
                process.env.VUE_APP_API_URL +
                `vote-buttons/readByWidgetId/${this.widget_id}`;
            const res = await fetch(url, {
                 headers: new Headers({
                     Authorization:
                         "Bearer " + this.$store.getters.logged_in_user.data.token,
                 }),
             });
            var data = await res.json();
            if(data){
                this.widget_settings = data;
            }
        },
        copyCode() {
            var copyText = document.getElementById("embed-code");
            copyText.select();
            copyText.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(copyText.value);

        },
        showEmbedCode(widget_id) {
            var __this = this;
            __this.embed_code_modal.show();
            var embed_code = `<div class="wbnz-vote-button" data-webynize-id="`+widget_id+`"></div>`;
            embed_code = embed_code + `<script src="${process.env.VUE_APP_WIDGET_URL}vote-button.js">` + `</`+`script>`;
            document.getElementById("embed-code").value = embed_code;
            __this.widget_id = widget_id;
            __this.read();
        }
    },
    mounted() {
        this.embed_code_modal = new Modal(document.getElementById("embed_code_modal"));
    },
};
</script>