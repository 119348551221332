<template>
    <div class="col-md-12">
        <table class="table table-hovered mt-5">
            <thead v-if="!data.data || data.data.length < 1">
                <tr>
                    No data found.
                </tr>
            </thead>
            <thead v-if="data.data && data.data.length > 0">
                <tr>
                    <th>Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody v-if="data.data && data.data.length > 0">
                <tr v-for="item in data.data" :key="item.id">
                    <td>
                        {{ item.name }}
                        <div v-if="item.widget_type_id == 1">
                            <router-link :to="'/update-widget/users/'+item.id+'/tooltips'"> 
                                {{ item.widget_type.name }}
                            </router-link>
                        </div>
                        <div v-else-if="item.widget_type_id == 2">
                            <router-link :to="'/update-widget/onboarding-checklist-items/'+item.id+'/'"> 
                                {{ item.widget_type.name }}
                            </router-link>
                        </div>
                        <div v-else-if="item.widget_type_id == 3">
                            <router-link :to="'/update-widget/quick-feedback/'+item.id+'/'"> 
                                {{ item.widget_type.name }}
                            </router-link>
                        </div>
                        <div v-else-if="item.widget_type_id == 6">
                            <router-link :to="'/update-widget/social-proofs/'+item.id+'/'"> 
                                {{ item.widget_type.name }}
                            </router-link>
                        </div>
                        <div v-else-if="item.widget_type_id == 4">
                            <router-link :to="'/update-widget/product-updates/'+item.id+'/'"> 
                                {{ item.widget_type.name }}
                            </router-link>
                        </div>
                        <div v-else-if="item.widget_type_id == 7">
                            <router-link :to="'/update-widget/vote-button/'+item.id+'/'"> 
                                {{ item.widget_type.name }}
                            </router-link>
                        </div>
                        <div v-else-if="item.widget_type">
                            {{ item.widget_type.name }}
                        </div>
                    </td>
                    <td>
                        <router-link v-if="item.widget_type_id == 1" class="btn btn-primary me-2"
                            :to="'/update-widget/tooltips/'+item.id+'/'"
                        > 
                            Edit
                        </router-link>
                        <router-link v-if="item.widget_type_id == 2" class="btn btn-primary me-2"
                            :to="'/update-widget/onboarding-checklist-items/'+item.id+'/'"
                        > 
                            Edit
                        </router-link>
                        <router-link v-if="item.widget_type_id == 3" class="btn btn-primary me-2"
                            :to="'/update-widget/quick-feedback/'+item.id+'/'"
                        > 
                            Edit
                        </router-link>
                        <router-link v-if="item.widget_type_id == 4" class="btn btn-primary me-2"
                            :to="'/update-widget/product-updates/'+item.id+'/'"
                        > 
                            Edit
                        </router-link>
                        <router-link v-if="item.widget_type_id == 6" class="btn btn-primary me-2"
                            :to="'/update-widget/social-proofs/'+item.id+'/'"
                        > 
                            Edit
                        </router-link>
                        <router-link v-if="item.widget_type_id == 7" class="btn btn-primary me-2"
                            :to="'/update-widget/vote-button/'+item.id+'/'"
                        > 
                            Edit
                        </router-link>
                        <button
                            @click="
                                $parent.$refs.deleteComponent.showDeleteModal(
                                    item.id
                                )
                            "
                            type="button"
                            class="btn btn-danger me-2"
                        >
                            Delete
                        </button>
                        
                    </td>
                    
                </tr>
            </tbody>
        </table><div class="loader" v-if="loading"></div>
        <nav
            v-if="data.data && data.data.length > 0"
            aria-label="Page navigation example"
        >
            <ul class="pagination">
                <span v-for="item in data.links" :key="item.url" class="me-1">
                    <li :class="{ active: item.active }" class="page-item">
                        <a
                            v-if="item.url"
                            @click="read(event, item.url)"
                            class="page-link"
                            href="#"
                            ><span v-html="item.label"></span
                        ></a>
                    </li>
                </span>
            </ul>
        </nav>
    </div>
</template>

<script>
import store from "../../../store";

export default {
    data() {
        return {
            loading: true,
            data: "",

            id: "",
            name: "",
            desciption: "",

            edit_modal: null,
            delete_modal: null,

            search_keyword: "",
        };
    },
    methods: {
        async read(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            // api request
            if (!url) {
                url =
                    process.env.VUE_APP_API_URL +
                    `widgets/${this.$store.getters.logged_in_user.data.user.id}`;
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            if (this.data && this.data.links) {
                this.data.links = "";
            }

            this.data = await res.json();
            this.loading = false;

            if (event) {
                event.target.disabled = false;
            }
        },
    },
    mounted() {
        this.read();
    },
};
</script>
