<template>
    <div class="col-sm-9">
        <h4>
            Admin Settings
        </h4>
    </div>
</template>

<script>

export default {
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>
