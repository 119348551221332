<template>
    <Create ref="createComponent" />
    <Read ref="readComponent" />
    <Update ref="updateComponent" />
    <Delete ref="deleteComponent" />
    <ChangePassword ref="changePasswordComponent" />
</template>

<script>
import Create from "./Create";
import Read from "./Read";
import Update from "./Update";
import Delete from "./Delete";
import ChangePassword from "./ChangePassword";

export default {
    components: {
        Create,
        Read,
        Update,
        Delete,
        ChangePassword,
    },

    mounted() {
        this.$refs.readComponent.read();
    },
};
</script>

<style scoped>
</style>