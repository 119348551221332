<template>
    <!-- create modal -->
    <div
        class="modal"
        id="create_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Create Type
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <input name="id" v-model="id" type="hidden" />

                    <div>Name</div>
                    <input
                        id="name"
                        name="name"
                        v-model="name"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Description</div>
                    <textarea
                        name="description"
                        v-model="description"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Icon</div>
                    <textarea
                        name="icon"
                        v-model="icon"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Status</div>
                    <select v-model="status" class="form-select mb-3">
                        <option value="1" selected>Live</option>
                        <option value="0">Coming Soon</option>
                    </select>
                    <div>Demo link</div>
                    <input
                        id="demo_link"
                        name="demo_link"
                        v-model="demo_link"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Definition link</div>
                    <input
                        id="definition_link"
                        name="definition_link"
                        v-model="definition_link"
                        type="text"
                        class="form-control mb-3"
                    />

                    <div>Tutorial link</div>
                    <input
                        id="tutorial_link"
                        name="tutorial_link"
                        v-model="tutorial_link"
                        type="text"
                        class="form-control mb-3"
                    />
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        @click="create($event)"
                        type="button"
                        class="btn btn-primary"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            id: "",
            name: "",
            description: "",
            icon: "",
            demo_link: "",
            definition_link: "",
            tutorial_link: "",
            create_modal: null,
        };
    },
    methods: {
        showCreateModal() {
            this.create_modal.show();
            document.getElementById("name").focus();
            this.resetFields();
        },
        resetFields() {
            this.id = "";
            this.name = "";
            this.description = "";
            this.icon = "";
            this.status = 0;
            this.demo_link = "";
            this.definition_link = "";
            this.tutorial_link = "";
        },
        async create(event) {
            event.target.disabled = true;

            axios
                .post(
                    process.env.VUE_APP_API_URL + "widget-types/create",
                    {
                        name: this.name,
                        status: this.status,
                        description: this.description,
                        icon: this.icon,
                        demo_link: this.demo_link,
                        definition_link: this.definition_link,
                        tutorial_link: this.tutorial_link,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " +
                                store.getters.logged_in_user.data.token,
                        },
                    }
                )
                .then(
                    (response) => {
                        if (response.data.id) {
                            this.$parent.$refs.readComponent.read();
                        }

                        event.target.disabled = false;
                        this.create_modal.hide();
                        axios.get(process.env.VUE_APP_WEBSITE_URL + `delete_all_cache.php`);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
    },
    mounted() {
        this.create_modal = new Modal(document.getElementById("create_modal"));
    },
};
</script>