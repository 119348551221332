<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Change Password</h4>

        <form @submit.prevent="changPassword">
            <div class="row">
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <label>New password</label>
                        <input class="form-control" v-model="newPassword" type="password" id="new-password" required>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <div class="form-group">
                        <label>Re-type password</label>
                        <input class="form-control" v-model="confirmPassword" type="password" id="confirm-password" required>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </form>

    </div>
</template>

<script>

import axios from "axios";
import store from "../store";

export default {
    data() {
        return {
            newPassword: "",
            confirmPassword: "",
        }
    },
    methods: {
        changPassword() {
            if (this.newPassword !== this.confirmPassword) {
                alert("The new password and re-typed password do not match.");
                return;
            }
            axios.put(
                process.env.VUE_APP_API_URL + "users/update_password",
                {
                    id: this.$store.getters.logged_in_user.data.user.id,
                    password: this.newPassword
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + store.getters.logged_in_user.data.token,
                    },
                }
            )
                .then(
                    (response) => {
                        if (response.data.success) {
                            alert('Password was updated successfully!')
                        }
                        else {
                            alert('Posting failed')
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    },
};
</script>
