<template>
    <div v-if="is_logged_in" class="col-sm-3">
        <div class="flex-shrink-0 p-3 bg-white" style="width: 280px">
            <ul class="list-unstyled ps-0">
                <li v-for="(navItem, i) in menuList" :key="i" class="mb-1">
                    <button
                        class="
                            btn btn-toggle
                            align-items-center
                            rounded
                            collapsed
                        "
                        data-bs-toggle="collapse"
                        data-bs-target="#home-collapse"
                        aria-expanded="navItem.expand"
                        @click.prevent="navItemCollapse(i)"
                    >
                        {{ navItem.name }}
                    </button>

                    <div
                        v-if="navItem.items.length > 0"
                        class="collapse"
                        :class="{ show: navItem.expand }"
                    >
                        <ul
                            class="
                                btn-toggle-nav
                                list-unstyled
                                fw-normal
                                pb-1
                                small
                            "
                        >
                            <li v-for="(subItem, j) in navItem.items" :key="j">
                                <router-link
                                    :to="{ path: subItem.link }"
                                    :class="
                                        $route.name == subItem.route_name
                                            ? 'active'
                                            : ''
                                    "
                                    class="link-dark rounded"
                                    >{{ subItem.name }}</router-link
                                >
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            menuList: [],
        };
    },
    name: "Sidebar",
    computed: {
        is_logged_in: function () {
            return this.$store.getters.is_authenticated;
        },
        role: function () {
            return this.$store.getters.logged_in_user.data.user.access_level;
        },
    },
    watch: {
        $route() {
            var __this = this;
            __this.menuList = [
                {
                    name: "Users",
                    expand: false,
                    items: [
                        {
                            name: "List",
                            link: "/admin/users",
                            route_name: "Users"
                        },
                        {
                            name: "Activities",
                            link: "#",
                        },
                        {
                            name: "Activity types",
                            link: "/admin/activity-types",
                            route_name: "ActivityTypes"
                        },
                    ],
                },
                {
                    name: "Widgets",
                    expand: false,
                    items: [
                        {
                            name: "List",
                            link: "",
                        },
                        {
                            name: "Types",
                            link: "/admin/widget-types",
                            route_name: "WidgetTypes",
                        },
                        {
                            name: "Categories",
                            link: "/admin/widget-categories",
                            route_name: "WidgetCategories",
                        },
                        {
                            name: "Stats",
                            link: "",
                        },
                    ],
                },
                {
                    name: "Content",
                    expand: false,
                    items: [
                        {
                            name: "Product Updates",
                            link: "/admin/product-updates",
                            route_name: "ProductUpdates",
                        },
                    ],
                },
                {
                    name: "Plans",
                    expand: false,
                    items: [
                        {
                            name: "Annually",
                            link: "/admin/plans/yearly",
                            route_name: "Plans",
                        },
                        {
                            name: "Monthly",
                            link: "/admin/plans/monthly",
                            route_name: "Plans",
                        },
                        {
                            name: "Inactive",
                            link: "/admin/plans/inactive",
                            route_name: "Plans",
                        },
                    ],
                },
                {
                    name: "Canned Responses",
                    expand: false,
                    items: [
                        {
                            name: "List",
                            link: "/admin/canned-responses",
                            route_name: "CannedResponses",
                        },
                    ],
                },
                {
                    name: "Invoices",
                    expand: false,
                    items: [
                        {
                            name: "List",
                            link: "/admin/invoices",
                            route_name: "Invoices",
                        },
                    ],
                },
                {
                    name: "Admin stats",
                    expand: false,
                    items: [
                        {
                            name: "Summary",
                            link: "/admin/admin-stats",
                            route_name: "Stats",
                        },
                        {
                            name: "Year",
                            link: "/admin/admin-stats",
                            route_name: "Stats",
                        },
                        {
                            name: "Month",
                            link: "/admin/admin-stats",
                            route_name: "Stats",
                        },
                    ],
                },
                {
                    name: "Admin settings",
                    expand: false,
                    items: [
                        {
                            name: "Profile",
                            link: "/admin/admin-settings",
                            route_name: "Settings",
                        },
                        {
                            name: "Settings",
                            link: "/admin/admin-settings",
                        },
                        {
                            name: "Sign out",
                            link: "/admin/admin-settings",
                        },
                    ],
                },
            ];

            if (
                __this.$store.getters.logged_in_user &&
                __this.$store.getters.logged_in_user.data.user.access_level ==
                    "support"
            ) {
                __this.menuList = __this.menuList.splice(
                    0,
                    __this.menuList.length - 2
                );
            }

            this.detectLink(this.$route);
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
        detectLink(router) {
            var counter = 0;
            for (let items of this.menuList) {
                for (let item of items.items) {
                    if (item.link == router.href) {
                        this.navItemCollapse(counter);
                        break;
                    }
                }
                counter++;
            }
        },
        navItemCollapse(index) {
            this.menuList = this.menuList.map((item, i) => {
                item.expand = !item.expand;
                if (i !== index) {
                    item.expand = false;
                }
                return item;
            });
        },
    },
    mounted() {},
};
</script>