<template>
    <div class="container p-5">
        <h4>
            Vote Button
        <button
            @click="$parent.$refs.embedCodeComponent.showEmbedCode($route.params.widget_id)"
            type="button"
            class="btn btn-primary me-2 float-end"
        >
            Embed on website
        </button>
        </h4>

        <br>
        <div class="row">
            <Sidebar/>
            <div class="col-md-8">
                <h5>Live Preview</h5>
                <div id="preview">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "./components/Sidebar";

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            widget_url: process.env.VUE_APP_WIDGET_URL,
            widget_id: ""
        };
    },
    mounted(){
        var __this = this;
        __this.widget_id = this.$route.params.widget_id;
    },
};
</script>