<template>
    <div
        class="modal"
        id="create_widget_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Create widget
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div>Type</div>
                    <select
                        id="widget_type_id"
                        name="widget_type_id"
                        type="text"
                        class="form-control mb-3"
                    >
                        <option
                            v-for="widget_type in widget_types"
                            :key="widget_type.id"
                            :value="widget_type.id"
                        >
                            {{ widget_type.name }}
                        </option>
                    </select>
                    <div>Name</div>
                    <input
                        v-model="name"
                        type="text"
                        class="form-control mb-3"
                        required
                    />
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";

export default {
    data() {
        return {
            widget_type_id : null,
            name: "",
        };
    },
    methods: {
        async create() {
            var select = document.getElementById("widget_type_id");
            this.widget_type_id = select.value;
            axios
            .post(
                process.env.VUE_APP_API_URL + "widgets",
                {
                    name: this.name,
                    user_id: this.$store.getters.logged_in_user.data.user.id,
                    widget_type_id: this.widget_type_id
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + store.getters.logged_in_user.data.token,
                    },
                }
            )
            .then(
                (response) => {
                    this.create_modal.hide();
                    if (response.data.id) {
                        if(this.widget_type_id == 1){
                            this.$router.push(`/tooltips/${response.data.id}`);
                        }
                        else if(this.widget_type_id == 2){
                            this.$router.push(`/onboarding-checklist-items/${response.data.id}`);
                        }
                        else{
                            this.$parent.$refs.readComponent.read();
                        }
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
    },
    props: ["widget_types", "create_modal"],
};
</script>
